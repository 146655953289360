import React, { ReactNode } from 'react';
import { RecipeWithPricing } from '@/models/Recipe';
import { ContentWidth, Dialog, DialogTrigger } from '@/components/ui/dialog';
import { RecipeDetailDialogContent } from '@/app/menus/[menuId]/components/recipe-card/RecipeDetailDialogContent';
import { RecipeReview } from '@/models/RecipeReview';

export type RecipeDetailDialogProps = {
    recipe: RecipeWithPricing;
    review?: RecipeReview | null;
    maxWidth?: ContentWidth;
    /** Children are used for the dialog trigger */
    children: ReactNode;
    readonly?: boolean;
};

export function RecipeDetailDialog({ recipe, children, maxWidth, readonly = false, review }: RecipeDetailDialogProps) {
    return (
        <Dialog>
            <DialogTrigger asChild>{children}</DialogTrigger>
            <RecipeDetailDialogContent recipe={recipe} maxWidth={maxWidth} readonly={readonly} review={review} />
        </Dialog>
    );
}

RecipeDetailDialog.displayName = 'RecipeDetailDialog';
