import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/util/styleUtils';

const alertVariants = cva(
    'relative w-full rounded-md border p-4 [&>svg~*]:pl-9 [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-5',
    {
        variants: {
            variant: {
                default: 'bg-purple-100 text-purple-800 border-purple-300 prose-alert-default',
                secondary: 'bg-blue-100 text-blue-800 border-blue-300 prose-alert-secondary',
                destructive: 'border-destructive/50 text-destructive dark:border-destructive [&>svg]:text-destructive',
            },
        },
        defaultVariants: {
            variant: 'default',
        },
    }
);

export type AlertProps = VariantProps<typeof alertVariants>;

const Alert = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement> & AlertProps>(
    ({ className, variant, ...props }, ref) => (
        <div ref={ref} role="alert" className={cn(alertVariants({ variant }), className)} {...props} />
    )
);
Alert.displayName = 'Alert';

const AlertTitle = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(
    ({ className, ...props }, ref) => (
        <h5 ref={ref} className={cn('mb-1 font-medium leading-none tracking-tight', className)} {...props} />
    )
);
AlertTitle.displayName = 'AlertTitle';

const AlertDescription = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
    ({ className, ...props }, ref) => (
        <div ref={ref} className={cn('text-sm [&_p]:leading-relaxed', className)} {...props} />
    )
);
AlertDescription.displayName = 'AlertDescription';

export { Alert, AlertTitle, AlertDescription };
