const Copy = {
    contact: {
        phone: 9704997053,
        phoneFormatted: '(970) 499-7053',
        email: 'support@prefaremeals.com',
    },
    basePageTitle: 'Prefare | Gourmet Meal Kits',
    supportUrl: 'https://support.prefare-denver.com/hc',
    siteDescription:
        'Prefare gourmet meal kits make it easier for you to enjoy delicious home cooking with the people you love.',
};
export default Copy;

export const buildPageTitle = (title: string) => {
    return `${title} | ${Copy.basePageTitle}`;
};
