import React from 'react';
import { StarIcon } from '@heroicons/react/20/solid';
import { QuoteIcon } from '@/components/icons/custom-icons';

export type TestimonialItem = { quote: string; stars?: number; name: string; byline?: string };
type Props = { item: TestimonialItem; hideStars?: boolean };
const TestimonialCard = ({ item: { quote, name, byline }, hideStars }: Props) => {
    return (
        <div className="relative">
            <span className="absolute -left-2 -top-1">
                <QuoteIcon className="text-teal-brand opacity-20" />
            </span>
            <div className="relative flex flex-col space-y-4 text-left">
                <p className="text-lg">{quote}</p>
                <div className="flex flex-col">
                    <p className="text-lg font-bold text-black">{name}</p>
                    {byline && <p className="text-md text-dark-grey">{byline}</p>}
                    {!hideStars && (
                        <div className="flex space-x-0.5">
                            <StarIcon className="h-8 w-8 text-orange" />
                            <StarIcon className="h-8 w-8 text-orange" />
                            <StarIcon className="h-8 w-8 text-orange" />
                            <StarIcon className="h-8 w-8 text-orange" />
                            <StarIcon className="h-8 w-8 text-orange" />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TestimonialCard;
