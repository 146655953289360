import useSWR from 'swr';
import Endpoint from '@/config/Endpoint';
import { Menu } from '@/models/Menu';

/**
 * Get all menus that are "active" and can be purchased by a customer. These menus are CLOSED=FALSE and PUBLISHED=TRUE
 */
export const useActiveMenus = () => {
    const { data: menus, error, isLoading, isValidating } = useSWR<Menu[]>(Endpoint.menus.active);

    const menusById = (menus ?? []).reduce<Record<number, Menu>>((map, r) => {
        map[r.id] = r;
        return map;
    }, {});

    const getById = (menuId: number): Menu | null => {
        return menusById[menuId] ?? null;
    };

    return { menus, isLoading, isValidating, menusById, error, getById };
};
