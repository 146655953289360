import { useFormikContext } from 'formik';
import { OrderFormValues } from '@/app/menus/[menuId]/components/OrderForm';
import { isDefined } from '@/util/TypeGuards';

type FormSummary = { num_recipes_selected: number; total_kits_selected: number };
export function useOrderFormHelpers() {
    const { values, setFieldValue, dirty } = useFormikContext<OrderFormValues>();
    const selections = values.selections;

    const summary = Object.values(selections).reduce<FormSummary>(
        (summary, qty) => {
            summary.total_kits_selected += qty ?? 0;
            if (isDefined(qty) && qty > 0) {
                summary.num_recipes_selected += 1;
            }
            return summary;
        },
        { num_recipes_selected: 0, total_kits_selected: 0 }
    );

    const removeSelection = async ({ recipe_id }: { recipe_id: number }) => {
        await setFieldValue(`selections[${recipe_id}]`, undefined);
    };

    const updateSelection = async ({ quantity, recipe_id }: { recipe_id: number; quantity: number }) => {
        if (Math.max(quantity, 0) === 0) {
            console.log(`[selections] removing recipe ${recipe_id}`);
            await removeSelection({ recipe_id });
        } else {
            console.log(`[selections] setting qty of  ${recipe_id} to ${quantity}`);
            await setFieldValue(`selections[${recipe_id}]`, quantity);
        }
    };

    const incrementSelection = async ({ recipe_id, step = 1 }: { recipe_id: number; step?: number }) => {
        const current_qty = values.selections[recipe_id] ?? 0;
        console.log(`[selections] current qty for recipe_id ${recipe_id} = ${current_qty}`, { selections });
        await updateSelection({ recipe_id, quantity: current_qty + step });
    };

    const decrementSelection = async ({ recipe_id, step = 1 }: { recipe_id: number; step?: number }) => {
        const current_qty = values.selections[recipe_id] ?? 0;
        if (current_qty === step) {
            await removeSelection({ recipe_id });
        } else {
            await updateSelection({ recipe_id, quantity: current_qty - step });
        }
    };

    const getSelection = ({ recipe_id }: { recipe_id: number }) => {
        const quantity = selections[recipe_id] ?? 0;
        return { recipe_id, quantity };
    };

    const getSelectedRecipeIds = () => {
        return Object.entries(selections)
            .filter(([, value]) => (value ?? 0) > 0)
            .map(([key]) => Number(key));
    };

    return {
        ...summary,
        removeSelection,
        updateSelection,
        incrementSelection,
        decrementSelection,
        getSelection,
        selections,
        getSelectedRecipeIds,
        dirty,
    };
}
