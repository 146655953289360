'use client';
import React from 'react';
import { LoginButton } from '@/components/AuthButtons';
import { LinkButton } from '@/components/ui/button';
import { useAuth0 } from '@auth0/auth0-react';
import useAuth from '@/hooks/useAuth';
import { ProfileDropdownMenu } from '@/components/ProfileDropdownMenu';

export type NavBarAuthButtonsProps = {};

export function NavBarAuthButtons({}: NavBarAuthButtonsProps) {
    const { isAuthenticated } = useAuth0();
    const { subscriber } = useAuth();
    const onboardingCompleted = (subscriber?.account_status ?? 0) > 0;
    return (
        <>
            {!isAuthenticated && (
                <>
                    <LoginButton variant="ghost" size={'sm'} minWidth={'sm'} />
                    <LinkButton href="/welcome" className="font-bold" size={'sm'} minWidth={'sm'}>
                        Sign Up
                    </LinkButton>
                </>
            )}
            {isAuthenticated && onboardingCompleted && subscriber && (
                <>
                    <LinkButton href={'/refer-a-friend'} variant={'ghost'} className={'hidden sm:flex'}>
                        Refer a Friend
                    </LinkButton>
                    <LinkButton href={'/dashboard'} variant={'ghost'}>
                        Dashboard
                    </LinkButton>
                    <ProfileDropdownMenu />
                </>
            )}
            {isAuthenticated && !onboardingCompleted && subscriber && (
                <LinkButton href="/welcome" className="font-bold">
                    Continue Setup
                </LinkButton>
            )}
        </>
    );
}

NavBarAuthButtons.displayName = NavBarAuthButtons;
