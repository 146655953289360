import React from 'react';
import { RecipeWithPricing } from '@/models/Recipe';
import { Button } from '@/components/ui/button';
import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid';
import { singularUnit } from '@/util/StringUtil';
import { useOrderFormHelpers } from '@/app/menus/[menuId]/components/useOrderFormHelpers';
import { useOrderPageContext } from '@/app/menus/[menuId]/components/MenuOrderContext';
import { Trash2Icon } from 'lucide-react';

export type RecipeCardActionsProps = { recipe: RecipeWithPricing; className?: string };

export function RecipeCardActions({ recipe, className }: RecipeCardActionsProps) {
    const { getSelection, incrementSelection, decrementSelection } = useOrderFormHelpers();
    const { editDisabled } = useOrderPageContext();
    const recipe_id = recipe.id;
    const { quantity } = getSelection({ recipe_id });

    if (quantity === 0) {
        return (
            <div className={className}>
                <Button
                    variant={'default'}
                    fullWidth
                    disabled={editDisabled}
                    onClick={async (e) => {
                        e.preventDefault();
                        await incrementSelection({ recipe_id });
                    }}
                >
                    {editDisabled ? 'Unavailable' : 'Add to order'}
                </Button>
            </div>
        );
    }

    const num_servings = quantity * recipe.qty_display_multiplier;

    return (
        <div className={className}>
            <div className={'flex items-center justify-between space-x-3 rounded-full border px-3 py-2'}>
                <div>
                    <span className={'whitespace-nowrap text-sm font-bold lowercase'}>
                        {num_servings}{' '}
                        {num_servings == 1
                            ? singularUnit(recipe.qty_units_display_name)
                            : recipe.qty_units_display_name}
                    </span>
                </div>
                <div className={'flex items-center'}>
                    <Button
                        disabled={editDisabled}
                        size={'icon-sm'}
                        onClick={async (e) => {
                            e.preventDefault();
                            await decrementSelection({ recipe_id });
                        }}
                    >
                        {quantity > 1 ? <MinusIcon className={'h-5 w-5'} /> : <Trash2Icon className={'h-4 w-4'} />}
                    </Button>
                    <span className={'px-3 text-center tabular-nums'}>{quantity}</span>
                    <Button
                        disabled={editDisabled}
                        size={'icon-sm'}
                        onClick={async (e) => {
                            e.preventDefault();
                            await incrementSelection({ recipe_id });
                        }}
                    >
                        <PlusIcon className={'h-5 w-5'} />
                    </Button>
                </div>
            </div>
        </div>
    );
}

RecipeCardActions.displayName = 'RecipeCardActions';
