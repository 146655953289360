import React from 'react';
import { Skeleton } from '@/components/ui/skeleton';
import { RecipeImage } from '@/components/RecipeImage';
import { RecipeDetailDialog } from '@/app/menus/[menuId]/components/recipe-card/RecipeDetailDialog';
import { useActiveMenus } from '@/hooks/useActiveMenus';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { DateFormat, formatDateStringWithOffsetDays } from '@/util/DateUtil';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

export function SkeletonCard() {
    return (
        <div className="flex flex-col space-y-3">
            <Skeleton className="aspect-h-3  aspect-w-5 h-[125px] w-full rounded-xl bg-gray-200/80" />
            <div className="space-y-2">
                <Skeleton className="h-4 w-full bg-gray-200/80" />
                <Skeleton className="h-4 w-full bg-gray-200/80" />
            </div>
        </div>
    );
}

function GridContainer({ children }: { children }) {
    return (
        <div className="mx-auto grid max-w-sm  grid-cols-1 gap-8 sm:max-w-4xl sm:grid-cols-2 sm:px-0 md:grid-cols-3">
            {children}
        </div>
    );
}

const MarketingDishes = () => {
    const { menus, isLoading } = useActiveMenus();

    if (isLoading) {
        return (
            <GridContainer>
                <SkeletonCard />
                <SkeletonCard />
                <SkeletonCard />

                <SkeletonCard />
                <SkeletonCard />
                <SkeletonCard />

                <SkeletonCard />
                <SkeletonCard />
                <SkeletonCard />
            </GridContainer>
        );
    }

    if (!menus) {
        return (
            <Alert className={'mx-auto max-w-screen-sm'} variant={'secondary'}>
                <InformationCircleIcon className={'size-6'} />
                <AlertTitle className={'font-bold'}>Whoops! Something is not quite right...</AlertTitle>
                <AlertDescription> Unable to load menus at this time. Please try again later.</AlertDescription>
            </Alert>
        );
    }

    return (
        <Tabs className={'flex w-full flex-col space-y-8'} defaultValue={`menu-${menus[0]?.id}`}>
            <TabsList className={'-mx-4 justify-start overflow-auto px-4 sm:justify-center'} variant={'pill'}>
                {menus?.map((menu) => (
                    <TabsTrigger key={menu.id} value={`menu-${menu.id}`} variant={'pill'}>
                        {formatDateStringWithOffsetDays(menu.menu_date, 0, DateFormat.monthDayShort)}
                    </TabsTrigger>
                ))}
            </TabsList>

            {menus?.map((menu) => {
                return (
                    <TabsContent value={`menu-${menu.id}`} key={menu.id} className={''}>
                        <GridContainer>
                            {menu?.recipes.map((recipe, i) => (
                                <RecipeDetailDialog recipe={recipe} key={`${recipe.name}${i}`} readonly>
                                    <div className="flex cursor-pointer flex-col items-center justify-start space-y-2 text-center last:hidden last:md:block">
                                        <div
                                            className={
                                                'aspect-h-5 aspect-w-5 w-full shrink-0 overflow-hidden rounded-xl'
                                            }
                                        >
                                            <RecipeImage recipe={recipe} className={'h-full w-full '} />
                                        </div>

                                        <p className="whitespace-pre-line text-balance font-bold">{recipe.name}</p>
                                    </div>
                                </RecipeDetailDialog>
                            ))}
                        </GridContainer>
                    </TabsContent>
                );
            })}
        </Tabs>
    );
};

export default MarketingDishes;
