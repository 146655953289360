import React from 'react';
import { RecipeWithPricing } from '@/models/Recipe';
import { formatCents, singularUnit } from '@/util/StringUtil';
import { cn } from '@/util/styleUtils';

export type RecipeKitPriceProps = { recipe: RecipeWithPricing; priceClassName?: string; wrapOnSmall?: boolean };

export function RecipeKitPrice({ recipe, priceClassName, wrapOnSmall = false }: RecipeKitPriceProps) {
    const price = recipe.menu_unit_price ?? recipe.unit_price;
    return (
        <div
            className={cn('flex items-baseline sm:space-x-2 ', {
                'flex-col sm:flex-row': wrapOnSmall,
                'space-x-2': !wrapOnSmall,
            })}
        >
            <span>
                <span className={cn('text-lg font-bold', priceClassName)}>{formatCents(price)}</span>
                <span className={'text-muted-foreground'}> / kit</span>
            </span>{' '}
            <span
                className={cn('space-x-0.5 text-sm text-muted-foreground ', {
                    "before:sm:content-['('] after:sm:content-[')']": wrapOnSmall,
                    "before:content-['('] after:content-[')']": !wrapOnSmall,
                })}
            >
                <span className="">{formatCents(Math.round(price / recipe.qty_display_multiplier))}</span>
                <span>per</span>
                <span>{singularUnit(recipe.qty_units_display_name)}</span>
            </span>
        </div>
    );
}

RecipeKitPrice.displayName = 'RecipeKitPrice';
