'use client';
import React, { ReactNode, useRef, useState } from 'react';
import { cn } from '@/util/styleUtils';
import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid';

type Props = { title: string; children: ReactNode };
const FAQItemView = ({ title, children }: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const [expanded, setExpanded] = useState(false);
    return (
        <div className="border-b border-grey">
            <div
                className="flex cursor-pointer justify-between px-4 py-4 transition md:items-center"
                onClick={() => setExpanded(!expanded)}
            >
                <span className="text-xl font-bold text-black">{title}</span>
                <span className="relative top-1.5 sm:top-0">
                    {expanded ? <MinusIcon className="h-4 w-4" /> : <PlusIcon className="h-4 w-4" />}
                </span>
            </div>
            <div
                ref={ref}
                className={cn('text-md space-y-4 overflow-hidden px-4 text-dark-grey transition-all', {
                    'mb-4': expanded,
                })}
                style={{ maxHeight: `${expanded && ref?.current ? ref.current.scrollHeight : 0}px` }}
            >
                {children}
            </div>
        </div>
    );
};

export default FAQItemView;
