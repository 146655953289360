'use client';
import React, { ReactNode, useState } from 'react';
import { RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react';
import LocalStorageService, { StorageKey } from '@/config/LocalStorageService';
import { zendeskApi } from '@/components/ZendeskWidget';
import { Button, ButtonProps } from '@/components/ui/button';
import { getLocationOrigin } from '@/util/StringUtil';
import { ensureLeadingSlash } from '@/util/url-util';
import { cn } from '@/util/styleUtils';

export type AuthMode = 'signup' | 'login';
export type AuthParams = {
    mode?: AuthMode;
    continueUrl?: string;
    text?: ReactNode;
};
export type AuthButtonProps = AuthParams & ButtonProps;

export const getAuthorizationParams = ({
    mode = 'login',
    continueUrl = '/auth-success',
}: AuthParams): RedirectLoginOptions['authorizationParams'] => {
    const options: RedirectLoginOptions['authorizationParams'] = { prompt: 'login' };
    if (mode === 'signup') {
        options.screen_hint = mode;
        options.initial_screen = 'signup';
        options.dict = {
            signin: {
                title: 'Continue your account setup',
            },
        };
    }
    if (continueUrl) {
        if (continueUrl.startsWith('http')) {
            options.redirect_uri = continueUrl;
        } else {
            options.redirect_uri = window.location.origin + ensureLeadingSlash(continueUrl);
        }
    }

    return options;
};

export const AuthButton = ({
    mode = 'login',
    text = 'Log In',
    continueUrl = '/dashboard',
    ...props
}: AuthButtonProps) => {
    const { loginWithRedirect } = useAuth0();
    const [loading, setLoading] = useState(false);
    const options: RedirectLoginOptions['authorizationParams'] = { prompt: 'login' };
    if (mode === 'signup') {
        options.screen_hint = mode;
        options.initial_screen = 'signup';
    }
    options.redirect_uri = getLocationOrigin() + '/auth-success';
    // options.redirect_uri = window.location.origin + ensureLeadingSlash(continueUrl);
    console.log('[AuthButton] signing in with redirect_uri of', options.redirect_uri);
    return (
        <Button
            {...props}
            isLoading={loading}
            onClick={() => {
                setLoading(true);
                if (continueUrl) {
                    console.log('setting continue url to ', continueUrl);
                    LocalStorageService.setItem(StorageKey.AUTH_REDIRECT_PATH, continueUrl);
                }

                setTimeout(async () => {
                    console.log('[AuthButtons] logging in with params', options);
                    await loginWithRedirect({ authorizationParams: options });
                }, 10);
            }}
        >
            {text}
        </Button>
    );
};

export const LoginButton = (props: AuthButtonProps) => {
    return <AuthButton {...props} text="Log In" mode="login" />;
};

export const useLogout = () => {
    const { logout } = useAuth0();
    const [loading, setLoading] = useState(false);
    const handleLogout = async () => {
        setLoading(true);
        LocalStorageService.clearAll();
        zendeskApi('webWidget', 'logout');
        await logout({
            logoutParams: {
                returnTo: window.location.origin,
            },
        });
    };
    return { handleLogout, loading };
};

export const LogOutButton = ({
    text = 'Sign out',
    link = false,
    variant = 'default',
    ...props
}: Omit<AuthButtonProps, 'mode'> & { link?: boolean }) => {
    const { handleLogout, loading } = useLogout();

    if (link) {
        return (
            <span onClick={handleLogout} className={cn('cursor-pointer text-blue hover:underline', props.className)}>
                {loading ? 'Loading...' : text}
            </span>
        );
    }

    return (
        <Button onClick={() => handleLogout()} {...props} type="button" variant={variant}>
            {text}
        </Button>
    );
};
