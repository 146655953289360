'use client';
import React from 'react';
import { PrefareLogo } from '@/components/icons/custom-icons';
import { LinkButton } from '@/components/ui/button';
import RouterLink from 'next/link';
import { NavBarAuthButtons } from '@/components/marketing/NavBarAuthButtons';
import { useAuth0 } from '@auth0/auth0-react';
import { cn } from '@/util/styleUtils';
type Props = { onlyAuthButtons?: boolean };
const NavBar = ({ onlyAuthButtons = false }: Props) => {
    const { isAuthenticated } = useAuth0();
    return (
        <nav className="bg-white py-4">
            <div className="container mx-auto px-4 ">
                <div className="flex items-center justify-between">
                    <RouterLink href="/">
                        <PrefareLogo className="hidden sm:block" />
                        <PrefareLogo className="sm:hidden" height={'24'} />
                    </RouterLink>
                    <div className="flex items-center justify-end space-x-2 print:hidden">
                        {!onlyAuthButtons && !isAuthenticated && (
                            <span className="hidden sm:block">
                                <LinkButton
                                    href={'/about'}
                                    variant={'ghost'}
                                    className={cn({ 'hidden md:inline-block': !onlyAuthButtons && isAuthenticated })}
                                >
                                    About Us
                                </LinkButton>
                            </span>
                        )}
                        <NavBarAuthButtons />
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default NavBar;
