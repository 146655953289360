import React from 'react';
import { RecipeWithPricing } from '@/models/Recipe';
import { RecipeImage } from '@/components/RecipeImage';
import RecipeTagList from '@/components/RecipeTagList';
import { RecipeDetailTabs } from '@/app/menus/[menuId]/components/recipe-card/tabs/RecipeDetailTabs';
import { RecipeCardActions } from '@/app/menus/[menuId]/components/recipe-card/RecipeCardActions';
import { ContentWidth, DialogContent } from '@/components/ui/dialog';
import { ClockIcon, ExternalLinkIcon, SoupIcon } from 'lucide-react';
import { RecipeKitPrice } from '@/app/menus/[menuId]/components/recipe-card/RecipeKitPrice';
import { RecipeReview } from '@/models/RecipeReview';
import { StarRatingDisplay } from '@/components/StarRatingDisplay';
import { LinkButton } from '@/components/ui/button';
import { makeSlug } from '@/util/StringUtil';

export type RecipeDetailDialogContentProps = {
    recipe: RecipeWithPricing;
    review?: RecipeReview | null;
    maxWidth?: ContentWidth;
    readonly?: boolean;
};

export function RecipeDetailDialogContent({
    recipe,
    review,
    maxWidth = '3xl',
    readonly = false,
}: RecipeDetailDialogContentProps) {
    return (
        <DialogContent noPadding maxWidth={maxWidth} className={'gap-0 bg-background'}>
            <div className={'relative'}>
                <div className={'aspect-h-3 aspect-w-5 overflow-hidden sm:rounded-t-lg'}>
                    <RecipeImage recipe={recipe} useFallback />
                </div>
                {review && (
                    <StarRatingDisplay
                        rating={review.rating}
                        unselectedClassName={'text-muted'}
                        containerClassName={'absolute bottom-2 left-2'}
                    />
                )}
                <div className={'absolute bottom-2 right-2'}>
                    <LinkButton
                        href={`/recipes/${makeSlug(recipe.name)}/${recipe.id}`}
                        size={'icon-sm'}
                        variant={'outlined'}
                        target={'_blank'}
                    >
                        <ExternalLinkIcon className={'size-4 text-blue'} />
                    </LinkButton>
                </div>
            </div>
            <div className={'space-y-4 overflow-hidden pt-4'}>
                <div className={'space-y-4 px-6'}>
                    <div className={'space-y-2'}>
                        <div className={'text-xl font-bold'}>{recipe.name}</div>
                        <RecipeTagList recipe={recipe} scroll className={'-mx-6 px-6'} />
                    </div>

                    <div className={'flex items-center justify-between'}>
                        <div className={'flex space-x-4'}>
                            {recipe.prep_time_minutes && (
                                <div className={'flex items-center space-x-1 lowercase'}>
                                    <ClockIcon className={'h-5 w-5'} />
                                    <span>{Math.round(recipe.prep_time_minutes)} minutes</span>
                                </div>
                            )}
                            <div className={'flex shrink-0 items-center space-x-1 lowercase'}>
                                <SoupIcon className={'h-5 w-5'} />
                                <span className={'whitespace-nowrap'}>
                                    {recipe.qty_display_multiplier} {recipe.qty_units_display_name} per kit
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <RecipeDetailTabs recipe={recipe} review={review} />
            </div>
            <div className={'sticky bottom-0 w-full border-t bg-background px-6 py-4 pb-6'}>
                <div className={'flex w-full items-center justify-between space-x-2 sm:space-y-0'}>
                    <RecipeKitPrice recipe={recipe} wrapOnSmall />
                    {!readonly && <RecipeCardActions recipe={recipe} />}
                </div>
            </div>
        </DialogContent>
    );
}

RecipeDetailDialogContent.displayName = 'RecipeDetailDialogContent';
