import React from 'react';
import FAQItemView from '@/components/marketing/FAQItemView';

const FAQSection = () => {
    return (
        <div>
            <FAQItemView title="Who makes the meals?">
                <p>
                    Our Culinary Director, Susan Ardito, has over 30 years of experience working in food service with
                    several restaurant concepts. After successfully running her own restaurant in New York, she joined
                    Prefare in 2015.
                </p>
                <p>
                    Susan and her team of skilled chefs proudly and meticulously create gourmet, yet easy to prepare
                    dishes that complement the seasons. From a warm butternut squash risotto perfect in December to the
                    signature Chicken Piccata with Caprese salad in July, you’ll love her local flavors.
                </p>
                <p>
                    Feel free to contact the culinary team with food related questions at:{' '}
                    <a href="mailto:chef@prefaremeals.com">chef@prefaremeals.com</a>.
                </p>
            </FAQItemView>
            <FAQItemView title="How much does it cost per serving?">
                <p>Each serving averages out to just under $11.00.</p>
                <p>
                    For comparison to dining out and delivery services, our actual cost per meal is around $8.89, when
                    delivery fees and taxes are excluded. What a deal right?
                </p>
            </FAQItemView>

            <FAQItemView title="Where does Prefare deliver?">
                <p>
                    We service the entire Denver Metro corridor and beyond! This means we go as far north as Fort
                    Collins, then all the way south to Castle Rock. We also deliver to communities around Evergreen and
                    Golden.
                </p>
                <p>
                    Our carefully calibrated refrigerated vans enable long distances while also eliminated all the
                    burdensome cardboard packaging and annoying ice packs that so many other services rely upon.
                </p>
            </FAQItemView>
            <FAQItemView title="When are meals delivered?">
                <p>
                    We currently offer Saturday, Sunday, or Monday for delivery by 9 AM. Meals arrive perfectly chilled
                    from our refrigerated vans on the morning of your selected delivery day and we carefully place each
                    meal in a cooler we provide to you totally free of charge.
                </p>
                <p>
                    Mountain communities default to Saturdays at this time while apartments get delivery during the day
                    on Monday.
                </p>
            </FAQItemView>
            <FAQItemView title="How flexible is the ordering platform?">
                <p>
                    Our technology platform fits to your changing lifestyle and enables complete customization. This
                    means you can use the pay-as-you-go option (default your weekly menu to zero meals each week) or
                    switch over to a subscription at a click of the button at any point in time.
                </p>
                <p>
                    The ordering process is identical to ordering from a restaurant menu, with no restrictions based on
                    your account settings.{' '}
                </p>
                <p>For ease and planning, we have over 15 rotating dishes posted over a month out at any given time.</p>
            </FAQItemView>
            <FAQItemView title="Can I skip a week or more?">
                <p>
                    You can certainly do this! When you are gone for an extended period of time, you can change to
                    pay-as-you-go in your settings, which means we default your weekly menu to zero meals.
                </p>
            </FAQItemView>
            <FAQItemView title="What do you mean by minimal waste?">
                <p>
                    We deliver each meal in a compact, fully recycled plastic container no bigger than a six pack, which
                    is designed to feed two. By creating a demand for reuse of plastic, we are confident that less of it
                    will end up in landfills as we begin the journey to a more sustaining economy.
                </p>
                <p>
                    As we use delivery vans, you no longer have to deal with insulated cardboard megaboxes. You simply
                    reserve a cooler to be delivered free of charge. We reuse all ice packs,.
                </p>
                <p>
                    Lastly, we are aggressively looking to eliminate all virgin plastic by the new year, offer net-zero
                    items through our service, as well as offset a large portion of our emissions.
                </p>
            </FAQItemView>
            <FAQItemView title="What kind of variety is offered each week?">
                <p>
                    We currently offer 15 choices each week with new items constantly in development. We started our
                    offering with meal kits but have expanded into salads, snacks, and even brunch!
                </p>
                <p>
                    Our broad selection of meals has something for everyone from carnivores to vegans, with each
                    category also featuring an array of family friendly and one pan meals.
                </p>
                <p>Seasonal menus throughout the year ensure you never get bored with your choices!</p>
            </FAQItemView>
            <FAQItemView title="Can you accommodate my diet and allergies?">
                <p>
                    Each of our detailed meal descriptions list the common allergens which they contain. We currently
                    track wheat (gluten), dairy, soy, egg, tree nuts (including coconut), peanuts, fish, and shellfish.
                </p>
                <p>
                    Please note: Food has been prepared in a kitchen that uses products containing wheat, soy, dairy,
                    peanuts, tree nuts, shellfish, fish and eggs, and may contain traces of these allergens.
                </p>
            </FAQItemView>
        </div>
    );
};

export default FAQSection;
