import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react';
import { Menu } from '@/models/Menu';
import Order from '@/models/Order';
import { zendeskApi } from '@/components/ZendeskWidget';
import { RecipeWithPricing } from '@/models/Recipe';
import { Subscriber } from '@/models/Subscriber';
import { isDefined } from '@/util/TypeGuards';

type OrderPageContextType = {
    menu: Menu;
    order: Order;
    subscriber: Subscriber;
    cartOpen: boolean;
    setCartOpen: Dispatch<SetStateAction<boolean>>;
    getRecipeById: (recipe_id: number) => RecipeWithPricing;
    editDisabled: boolean;
    confirmOpen: boolean;
    setConfirmOpen: Dispatch<SetStateAction<boolean>>;
};
const OrderPageContext = createContext<OrderPageContextType | null>(null);

export const useOrderPageContext = () => {
    const ctx = useContext(OrderPageContext);
    if (!ctx) {
        throw new Error('No OrderPageContext was found');
    }
    return ctx;
};

export type OrderPageProviderProps = { menu: Menu; order: Order; children: ReactNode; subscriber: Subscriber };

export function OrderPageProvider({ menu, order, children, subscriber }: OrderPageProviderProps) {
    const [cartOpen, setCartOpen] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    useEffect(() => {
        zendeskApi('webWidget', cartOpen ? 'hide' : 'show');
    }, [cartOpen]);

    const getRecipeById = (recipe_id: number) => {
        const found = (menu.recipes ?? []).find((recipe) => recipe.id === recipe_id);
        if (!found) {
            throw new Error(`Unable to find recipe ${recipe_id}in menu ${menu.id}`);
        }
        return found;
    };

    const editDisabled =
        order.billed || isDefined(order.billing_status) || menu.closed || !menu.published || order.skip === true;

    return (
        <OrderPageContext.Provider
            value={{
                menu,
                order,
                subscriber,
                cartOpen,
                setCartOpen,
                getRecipeById,
                editDisabled,
                confirmOpen,
                setConfirmOpen,
            }}
        >
            <>{children}</>
        </OrderPageContext.Provider>
    );
}
OrderPageProvider.displayName = 'OrderPageProvider';
