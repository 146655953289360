import { FacebookIcon, InstagramIcon, PrefareLogo } from '@/components/icons/custom-icons';
import RouterLink from 'next/link';
import React from 'react';
import Copy from '@/config/Copy';

const PageFooter = () => {
    return (
        <footer className="bg-teal-light py-16">
            <div className="container flex flex-col space-x-0 px-4 md:space-x-8 lg:flex-row">
                <div className="mb-8 flex grow-0 flex-col space-y-4">
                    <PrefareLogo />
                </div>
                <div className="grid grid-cols-2 md:grid-cols-4">
                    <div className="mb-4 flex flex-col justify-start space-y-2 px-4">
                        <p className="font-bold">Information</p>
                        <span>
                            <RouterLink href={'/about'} className="">
                                About Us
                            </RouterLink>
                        </span>
                    </div>
                    <div className="mb-4 flex flex-col space-y-2 px-4">
                        <p className="font-bold">Account</p>
                        <span>
                            <RouterLink href={'/login'} className="">
                                Log In
                            </RouterLink>
                        </span>
                        <span>
                            <RouterLink href={'/welcome'} className="">
                                Sign Up
                            </RouterLink>
                        </span>
                    </div>
                    <div className="mb-4 flex flex-col space-y-2 px-4">
                        <p className="font-bold">Contact Us</p>
                        <span>
                            <a href={`mailto:${Copy.contact.email}`}>Email</a>
                        </span>
                    </div>
                    <div className="mb-4 flex flex-col space-y-2 px-4">
                        <p className="font-bold">Follow Us</p>
                        <div className="flex space-x-4">
                            <span>
                                <a href="https://www.facebook.com/prefaremeals" target="_blank" rel="noreferrer">
                                    <FacebookIcon />
                                </a>
                            </span>
                            {/*<a href="">*/}
                            {/*    <TwitterIcon />*/}
                            {/*</a>*/}
                            <span>
                                <a href="https://www.instagram.com/prefaremeals/" target="_blank" rel="noreferrer">
                                    <InstagramIcon />
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default PageFooter;
