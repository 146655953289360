'use client';
import React, { ReactNode } from 'react';
import { Carousel } from 'react-responsive-carousel';

import TestimonialCard, { TestimonialItem } from '@/components/marketing/TestimonialCard';
import { cn } from '@/util/styleUtils';
import { ChevronDoubleRightIcon, ChevronDoubleLeftIcon } from '@heroicons/react/20/solid';

const testimonials: TestimonialItem[] = [
    {
        quote: 'I have tried many other food delivery companies, PREFARE IS BY FAR THE BEST!  The food is hand delivered, not shipped for days from outta state! Prefare even texts you when about to deliver your order. The food is fresh and flavorful! My family loves the new recipes! Quick to prep and the portions are prefect. Little mess and very little clean up! I tell everyone I can about this great company!',
        name: 'Jen B.',
    },
    {
        quote: "Supporting local small business and receiving fresh, delicious meal kits in return-- what could be better than late? The national meal services couldn't compare with their 2 hour prep times. Prefare has helped us survive infancy and we love them.",
        name: 'Sam S.',
    },
    {
        quote: 'I started this meal delivery three weeks ago and after using three other services (Green Chef, Blue Apron, and Dream Dinners) this is by far the best. The food is fresh, easy, and tastes great.',
        name: 'Danielle S.',
    },
    {
        quote: 'Super impressed! Great company to deal with! The food is amazing.. Dinner comes together super quick..my family loves the no stress new flavor..healthy meals!',
        name: 'Emily M.',
    },
];

const TestimonialCarousel = () => {
    const renderIndicator = (
        _clickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void,
        isSelected: boolean
    ): ReactNode => {
        return (
            <span
                className={cn('inline-block h-4 w-4 rounded-full  ', {
                    'border-[4px] border-orange bg-white': isSelected,
                    'border-[4.5px] border-white bg-grey opacity-50': !isSelected,
                })}
            />
        );
    };

    return (
        <div className="relative">
            <Carousel
                renderIndicator={renderIndicator}
                autoPlay
                showStatus={false}
                infiniteLoop={true}
                showArrows
                showThumbs={false}
                renderArrowPrev={(onClick, hasPrev) => (
                    <div className="absolute left-0 top-1/3 z-10">
                        <ChevronDoubleLeftIcon
                            className={cn('h-6 w-6', {
                                'text-grey opacity-50': !hasPrev,
                                'text-teal cursor-pointer': hasPrev,
                            })}
                            onClick={onClick}
                        />
                    </div>
                )}
                renderArrowNext={(onClick, hasNext) => (
                    <div className="absolute right-0 top-1/3 z-10">
                        <ChevronDoubleRightIcon
                            className={cn('h-6 w-6', {
                                'text-grey opacity-50': !hasNext,
                                'text-teal cursor-pointer': hasNext,
                            })}
                            onClick={onClick}
                        />
                    </div>
                )}
            >
                {testimonials.map((item) => (
                    <div key={item.name} className="mx-auto max-w-5xl px-8 py-8 sm:px-12">
                        <TestimonialCard item={item} />
                    </div>
                ))}
            </Carousel>
        </div>
    );
};

export default TestimonialCarousel;
