'use client';
import React from 'react';
import NavBar from '@/components/marketing/NavBar';
import { LinkButton } from '@/components/ui/button';
import MarketingDishes from '@/components/marketing/MarketingDishes';
import TestimonialCarousel from '@/components/marketing/TestimonialCarousel';
import FAQSection from '@/components/marketing/FAQSection';
import PageFooter from '@/components/marketing/PageFooter';
import ReferralBanner from '@/components/marketing/ReferralBanner';
const MarketingHomePage = () => {
    return (
        <div className="min-h-screen">
            <ReferralBanner />
            <NavBar />
            <div className="relative">
                <img
                    src="https://cdn.prefare-denver.com/images/bg-salad-large.jpeg"
                    className="absolute left-0 top-0 h-full w-full object-cover object-top"
                    alt="Family enjoying a Prefare meal"
                />
                <div className="absolute left-0 top-0 z-10 h-full w-full bg-black bg-opacity-20" />
                <div className="container relative z-10 mx-auto space-y-8 px-4 py-20 text-center font-bold text-white md:py-32 lg:space-y-16 lg:py-60">
                    <div className="">
                        <h2 className="text-4xl font-bold sm:text-6xl md:text-7xl">Delicious Family Dinners</h2>
                        <p className="text-4xl md:text-5xl">Less Than 15 minutes. Really.</p>
                    </div>
                    <div className={''}>
                        <p className="text-2xl md:text-3xl">Chef Prepped. Locally Made. No Commitment.</p>
                        <p className="text-xl md:text-2xl">Starting at $10 per serving</p>
                    </div>
                    <div>
                        <LinkButton href="/welcome" size="xl">
                            Get Started
                        </LinkButton>
                    </div>
                </div>
            </div>
            <section className="bg-white py-20 text-center">
                <div className="container space-y-8 px-4">
                    <div className="flex items-center space-x-4">
                        <span className="hidden h-0 flex-grow border border-gray-300 lg:inline-block" />
                        <h3 className="text-4xl font-bold text-green-dark">#1 Local Meal Delivery Service in Denver</h3>
                        <span className="hidden h-0 flex-grow border border-gray-300 lg:inline-block" />
                    </div>
                    <div className="grid grid-cols-2 content-center items-center justify-center gap-4 text-center md:grid-cols-5">
                        <div className="flex items-center justify-center">
                            <img
                                src="https://cdn.prefare-denver.com/images/companies/nextdoor.png"
                                className="object-contain"
                                alt="Facebook logo"
                            />
                        </div>
                        <div className="flex items-center justify-center">
                            <img
                                src="https://cdn.prefare-denver.com/images/companies/macaroni-kid.png"
                                className="object-contain"
                                alt="Macaroni Kid Logo"
                            />
                        </div>
                        <div className="flex items-center justify-center">
                            <img
                                src="https://cdn.prefare-denver.com/images/companies/yelp.png"
                                className="object-contain"
                                alt="Yelp Logo"
                            />
                        </div>
                        <div className="flex items-center justify-center">
                            <img
                                src="https://cdn.prefare-denver.com/images/companies/facebook.png"
                                className="object-contain"
                                alt="Facebook Logo"
                            />
                        </div>
                        <div className="col-span-2 flex items-center justify-center md:col-span-1">
                            <img
                                src="https://cdn.prefare-denver.com/images/companies/denver-ear.png"
                                className="object-contain"
                                alt="The Denver Ear Logo"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="space-y-8 bg-green-dark py-20 text-center text-white">
                <div className="container mx-auto px-4">
                    <h1 className="text-4xl font-bold text-white">How It Works</h1>
                </div>
                <div className="container mx-auto grid grid-cols-1 gap-8 px-4 pb-8 md:grid-cols-3">
                    <div className="flex items-center justify-center">
                        <div className="flex max-w-[300px] flex-col items-center justify-center space-y-8">
                            <img
                                src="https://cdn.prefare-denver.com/images/noun_vegetables.svg"
                                className="object-contain"
                                alt="Facebook Logo"
                            />
                            <div className="space-y-2">
                                <h4 className="text-2xl font-bold">Customize your menu</h4>
                                <p>Select your weekly menu from our varied list of delicious meals.</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-center">
                        <div className="flex max-w-[300px] flex-col items-center justify-center space-y-8">
                            <img
                                src="https://cdn.prefare-denver.com/images/noun_delivery.svg"
                                className="object-contain"
                                alt="Facebook Logo"
                            />
                            <div className="space-y-2">
                                <h4 className="text-2xl font-bold">Await your delivery</h4>
                                <p>Our refrigerated vans deliver your meals right to your doorstep.</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-center">
                        <div className="flex max-w-[300px] flex-col items-center justify-center space-y-8">
                            <img
                                src="https://cdn.prefare-denver.com/images/noun_meal.svg"
                                className="object-contain"
                                alt="Facebook Logo"
                            />
                            <div className="space-y-2">
                                <h4 className="text-2xl font-bold">Enjoy your meal</h4>
                                <p>Spend less than 15 minutes cooking up your meal in the kitchen.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <LinkButton href="/welcome" size={'xl'} variant={'orange'} color={'primary'}>
                        Customize Your Menu
                    </LinkButton>
                </div>
            </section>
            <section className="py-20 ">
                <div className="container space-y-8 px-4">
                    <div className="">
                        <p className="text-center text-2xl font-bold text-green-dark">
                            There&apos;s Something for Everyone
                        </p>
                        <div className="mx-auto flex max-w-md items-center justify-center space-x-4">
                            <span className="hidden h-0 flex-grow border border-gray-300 text-center sm:inline-block" />
                            <h3 className="text-lg">starting at $10 per serving</h3>
                            <span className="hidden h-0 flex-grow border border-gray-300 sm:inline-block" />
                        </div>
                    </div>
                    <MarketingDishes />
                </div>
            </section>
            {/*<section className="space-y-8 bg-teal-light py-20 text-foreground">*/}
            {/*    <div className="container mx-auto px-4">*/}
            {/*        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">*/}
            {/*            <div className="flex items-center justify-center">*/}
            {/*                <div className="tw relative flex h-[300px] w-[300px] items-center justify-center lg:h-[400px] lg:w-[400px] xl:h-[500px] xl:w-[500px]">*/}
            {/*                    <img*/}
            {/*                        src="https://cdn.prefare-denver.com/images/salmon-before.png"*/}
            {/*                        className="absolute left-0 top-0 h-full max-h-[500px] w-full max-w-[500px] object-cover"*/}
            {/*                        alt="Facebook Logo"*/}
            {/*                    />*/}
            {/*                    <img*/}
            {/*                        src="https://cdn.prefare-denver.com/images/salmon-after.png"*/}
            {/*                        className="absolute left-0 top-0 h-full max-h-[500px] max-w-[500px] animate-dish-grow object-cover object-left"*/}
            {/*                        alt="Facebook Logo"*/}
            {/*                    />*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="flex items-center justify-center">*/}
            {/*                <div className="max-w-md">*/}
            {/*                    <h1 className="text-4xl font-bold text-green-dark ">*/}
            {/*                        The personal chef you&apos;ve always wanted*/}
            {/*                    </h1>*/}
            {/*                    <p className="mb-4 text-xl">Let us handle everything</p>*/}
            {/*                    <ul className="list-none space-y-2 text-2xl font-bold">*/}
            {/*                        <li className="flex space-x-2 py-2">*/}
            {/*                            <CheckCircle className="text-orange" />*/}
            {/*                            <span className="">All the meal planning</span>*/}
            {/*                        </li>*/}
            {/*                        <li className="flex space-x-2 py-2">*/}
            {/*                            <CheckCircle className="text-orange" />*/}
            {/*                            <span className="">All the grocery shopping</span>*/}
            {/*                        </li>*/}
            {/*                        <li className="flex space-x-2 py-2">*/}
            {/*                            <CheckCircle className="text-orange" />*/}
            {/*                            <span className="">All the food prep</span>*/}
            {/*                        </li>*/}
            {/*                    </ul>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}

            <section className="bg-white py-24">
                <div className="container px-4">
                    <h3 className="text-center text-4xl font-bold text-green-dark">Hear what others are&nbsp;saying</h3>
                    <TestimonialCarousel />
                </div>
            </section>

            <section className="space-y-12 bg-green-dark py-20 text-center text-white">
                <div className="container mx-auto space-y-2 px-4">
                    <p className="text-4xl font-bold text-white">Actually Commitment Free</p>
                    <p className="mx-auto max-w-2xl text-xl">
                        Its not another subscription service. Receive weekly auto deliveries or opt for a la carte meals
                        just when you need them.
                    </p>
                </div>
                <div className="flex items-center justify-center">
                    <LinkButton href="/welcome" size={'xl'} variant={'orange'} color={'primary'}>
                        Sign Up Now
                    </LinkButton>
                </div>
            </section>

            <section className="bg-white py-24" id="faq">
                <div className="container px-4 ">
                    <h3 className="mb-4 text-center text-4xl font-bold text-green-dark">Frequently Asked Questions</h3>
                    <div className="mx-auto max-w-4xl">
                        <FAQSection />
                    </div>
                </div>
            </section>
            <PageFooter />
        </div>
    );
};

export default MarketingHomePage;
