'use client';
// import { useAppGlobalContext } from '@components/contexts/AppGlobalContext';
import usePublicReferralCode from '@/hooks/usePublicReferralCode';
import Mono from '@/components/elements/Mono';
import React from 'react';
import Bold from '@/components/elements/Bold';
import { formatCents } from '@/util/StringUtil';
import useAuth from '@/hooks/useAuth';

const ReferralBanner = () => {
    const { subscriber, isLoading } = useAuth();
    // const { sessionReferralCode } = useAppGlobalContext();
    const sessionReferralCode = null;
    const { data: referralCode } = usePublicReferralCode({ code: sessionReferralCode });

    const isEligible = !subscriber || subscriber?.account_status === 0;
    if (!referralCode || !isEligible || isLoading) {
        return null;
    }

    return (
        <div className="flex w-full justify-center bg-teal-brand px-4 py-2 text-white">
            <div className="text-center">
                Get <Bold>{formatCents(referralCode.recipient_value)}</Bold> off your first order with
                referral&nbsp;code&nbsp;
                <Bold>
                    <Mono>{referralCode.code}</Mono>
                </Bold>
            </div>
        </div>
    );
};

export default ReferralBanner;
