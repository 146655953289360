import useSWR from 'swr';
import { PublicReferralCode } from '@/models/ReferralCode';
import Endpoint from '@/config/Endpoint';
import { isDefined } from '@/util/TypeGuards';

type Props = { code: string | null };
const usePublicReferralCode = ({ code }: Props) => {
    const { data, error, ...rest } = useSWR<PublicReferralCode | null>(
        () => (code ? Endpoint.publicReferralCode(code) : undefined),

        { shouldRetryOnError: false, refreshInterval: 0 }
    );

    const loading = !data && !error && code;

    return { data, error, hasReferralCode: isDefined(data), loading, ...rest };
};

export default usePublicReferralCode;
